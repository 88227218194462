.tblCalendar {
  overflow: hidden;
  table-layout: fixed;

  .calCell {
    cursor: pointer;
    position: relative;
    background-clip: padding-box; /* background-clip so position relative doesn't loose cell borders */
  }
  .calCell:hover {
    background-color: #F8F8F8
  }
  .cellTitle {
    position: absolute;
    white-space: nowrap;
    z-index: 1;
  }
  .calCellFirst {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .calCellSunday {
    background-color: #FFFAF0;
  }
  .calCellSaturday {
    background-color: #FFFFF8;
  }
  .calCellToday {
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-left-color: #FF7777;
    border-right-color: #FF7777;
  }
  .calCellWithOffer {
    background-color: #E7E7E7;
    .calCellSaturday {
      background-color: #E7E7E2;
    }
    .calCellSunday {
      background-color: #E7E3DC;
    }
  }
  .calCellWithOffer:hover {
    background-color: #E0E0E0 !important;
  }
  .calCellWithAdvance {
    background-color: #ECF7FF;
    .calCellSaturday {
      background-color: #ECF7FE;
    }
    .calCellSunday {
      background-color: #ECF6FC;
    }
  }
  .calCellWithAdvance:hover {
    background-color: #EAF5FD !important;
  }
  .calCellWithReservation {
    background-color: #B3DFFF;
    .calCellSaturday {
      background-color: #B3DFFA;
    }
    .calCellSunday {
      background-color: #B3DBF4;
    }
  }
  .calCellWithReservation:hover {
    background-color: #ACD8F8 !important;
  }

  .iconCell {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .iconCellRemoved {
    opacity: 0.3;
  }
  .iconCellBreakdown {
    color: red;
  }
  .spnBulkCounter {
    opacity: 0.25;
    color: #000000;
    position: absolute;
    top: 1rem;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 1.2rem;
    text-align: center;
  }
  .spnMarkingCounter,
  .spnBulkCounter.hasSelection {
    background-color: #555555;
    opacity: 0.8;
    color: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    left: 5px;
    bottom: 5px;
    font-size: 0.8rem;
    height: 20px;
    width: 20px;
    text-align: center;
  }
  .spnBulkCounter.hasSelection {
    background-color: #DDDDDD;
    color: #000000;
    top: auto;
    right: 5px;
    left: auto;
    width: 30px;
  }
}
