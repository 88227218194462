$colorPrimary: #2a9d8f;

/* Bootstrap overrides */

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1500px // Orig 1140px
);

$primary: $colorPrimary;

/* end of Bootstrap overrides */

@import "../../node_modules/bootstrap/scss/bootstrap";

html,
body {
    min-height: 100vh;
}
body {
    border-bottom: 4px solid $colorPrimary;
}

table {
    font-size: 1rem;
}

.cursor-pointer{
    cursor: pointer;
}

.buttons {
    margin-bottom: 1vh;
    button, a.btn {
        margin-right: 0.5vw;
        margin-bottom: 0.25vw;
    }
}

.containerMain {
    margin-bottom: 1vh;
    padding-top: 3vh;
    padding-bottom: 1vh;
}
.containerInner {
    background-color: #FFFFFF;
    padding: 2vh 1vw;

    .btnMaterialTableMenu {
        font-size: 1.2rem;
        color: $colorPrimary;
    }
}

.noBorder {
    border: none !important;
}
tr.noBorder {
    td, th {
        border: none !important;
    }
}

@keyframes blink {
    0%   {opacity: 1;}
    50%  {opacity: 0;}
    100%  {opacity: 1;}
}
.conLoadingSpinner {
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    margin: auto;

    .loadingSpinner {
        padding: 15px 0;
        animation-name: blink;
        animation-duration: 2s;
        animation-iteration-count: infinite;

        img {
            height: auto;
            width: 200px;
        }
    }
}

.loading {
    background-image: url("../../src/pictures/loadingSpinner.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    * {
        opacity: 0.8;
    }
}
.btn.loading,
button.loading {
    background-size: auto 90%;
    opacity: 0.8;
    * {
        opacity: 1;
    }
}

.btnRound {
    width: 56px;
    height: 56px;
    border-radius: 28px;
}
.btnRoundSmall {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.btnRoundVerySmall {
    width: 36px;
    height: 36px;
    border-radius: 18px;
}
.addButton {
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    z-index: 200;
    border-radius: 30px;
    height: 60px;
    width: 60px;
    padding: 14px;
}

.headerPage {
    color: $colorPrimary;
    margin-left: 1.2vw;
    margin-bottom: 2vh;
}

input:invalid {
    background-color: #FCEBED;
}
[type="text"][value=""]:invalid,
[type="password"][value=""]:invalid,
[type="email"][value=""]:invalid {
    background-color: #DC3545;
    opacity: 0.1;
}

#responsive-navbar-nav {
    .dropdown-menu {
        .dropdown-item:active {
            background-color: lightgray;
        }
        .dropdown-item.active {
            background-color: darkgrey;
        }
    }
}

#navbarDriving,
#navbarCustomer {
    .nav-link {
        border-radius: 6px;
        color: $colorPrimary;
        font-size: 1rem;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .nav-link.active {
        background-color: #f0f0f0;
    }
    @media (max-width: 991px) {
        .nav-link {
            font-size: 0.9rem;
        }
    }
    @media (max-width: 767px) {
        .nav-link {
            font-size: 1rem;
            padding-left: 2vw;
            padding-right: 2vw;
        }
    }
}

.MuiDialogContent-root {
    @media (max-width: 767px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .MuiFormControl-root {
        margin-bottom: 2vh;
    }
    .MuiFormControl-root.MuiTextField-root {
        margin-bottom: 0;
    }
}

.MuiTable-root {
    margin-bottom: 3vh;
}

$cellWidthCatalogLarge: 8%;
$cellWidthCatalogNorm: 6%;
$cellWidthCatalogSmall: 4%;

.tblCatalog {
    background-color: #FFFFFF;
    margin: 0;
    min-width: 1200px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 3%;
    }
    th:nth-child(2),
    td:nth-child(2) {
        width: calc(40% - 280px - 135px);
    }
    th:nth-child(3),
    td:nth-child(3) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(4),
    td:nth-child(4) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(5),
    td:nth-child(5) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(6),
    td:nth-child(6) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(7),
    td:nth-child(7) {
        width: $cellWidthCatalogSmall;
    }
    th:nth-child(8),
    td:nth-child(8) {
        width: 1%;
    }
    th:nth-child(9),
    td:nth-child(9) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(10),
    td:nth-child(10) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(11),
    td:nth-child(11) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(12),
    td:nth-child(12) {
        width: $cellWidthCatalogNorm;
    }
    th:nth-child(13),
    td:nth-child(13) {
        width: $cellWidthCatalogSmall;
    }
    th:nth-child(14),
    td:nth-child(14) {
        width: 280px;

        .MuiFormControl-root {
            width: 260px;
        }
    }
    th:nth-child(15),
    td:nth-child(15) {
        width: 135px;
    }
}
.tblCatalog.sortingCategoryUpper.hideOnCategoryUpperSort {
    display: none;
}
.tblCatalog.sortingCategory .hideOnCategorySort {
    display: none;
}
.tblCatalog.extra.rental {
    th:nth-child(1),
    td:nth-child(1) {
        display: none;
    }
    th:nth-child(2),
    td:nth-child(2) {
        width: calc(40% - 180px - 135px - 180px);
    }
    th:nth-child(14),
    td:nth-child(14) {
        width: 1%;
    }
    th:nth-child(15),
    td:nth-child(15) {
        width: 180px;

        .MuiFormControl-root {
            width: 160px;
        }
    }
    th:nth-child(16),
    td:nth-child(16) {
        width: 135px;

        .MuiFormControl-root {
            width: 115px;
        }
    }
    th:nth-child(17),
    td:nth-child(17) {
        width: 180px;
    }
}

.tblDiscounts {
    .cellPriceChange {
        background-color: #F5F5F5;
        max-width: 175px;
        input {
            max-width: 150px;
        }
    }
}

.conWorkQueue {
    background-color: #F2F2F2;
    padding: 1rem;
    border-radius: 15px;
    margin-bottom: 2rem;

    .titleWorkQueue {
        color: #767171;
        font-size: 1rem;
        font-weight: bold;
        min-height: 2.5rem;
        padding-top: 0.5rem;
    }

    .cardWorkQueue {
        margin-top: 1rem;
        background-color: #FFFFFF;
        padding: 0.5rem;
        border-radius: 5px;
        box-shadow: 1px 1px 3px #888;

        .cardWorkQueueActions {
            display: flex;
            margin-bottom: 0.5rem;

            .cardWorkQueueActionCol.left {
                flex: 2;
            }
            .cardWorkQueueActionCol.right {
                flex: 1;
                text-align: right;
            }

            button {
                margin-right: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }

        .cardWorkQueueBottom {
            margin-top: 0.25rem;

            .cardWorkQueueComments {
                margin-top: 0.5rem;

                .cardWorkQueueComment {
                    background-color: #F5F5F5;
                    padding: 1rem;
                    border-radius: 5px;
                    margin-bottom: 0.5rem;

                    .cardWorkQueueCommentUser {

                        .user {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
.tblInvoice {
    th, td {
        vertical-align: middle;
    }
}
.iconBreakdownRentableYes {
    color: orange;
}
.iconBreakdownRentableNo {
    color: red;
}
.iconBreakdownFixed {
    color: green;
}